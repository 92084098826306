










































import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  name: 'RadioQuestion',
})
export default class RadioQuestion extends Vue {
  @Prop() value!: any;
  @Prop() schema!: any;

  get id() {
    return `Q${this.schema.questionIndex}`;
  }

  get model() {
    const response = this.value || {};
    return response.value;
  }

  set model(newValue) {
    const selected = this.schema.choices.find(
      (choice) => choice.value === newValue
    );
    this.$emit('input', {
      text: selected.text,
      value: selected.value,
    });
  }
}
