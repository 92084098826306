



















import _ from 'lodash';
import {
  RatingQuestion,
  RadiogroupQuestion,
  CheckboxQuestion,
  DropdownQuestion,
  TextQuestion,
  CommentQuestion,
} from '../survey';

import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  name: 'SurveyQuestion',
  components: {
    RatingQuestion,
    RadiogroupQuestion,
    CheckboxQuestion,
    DropdownQuestion,
    TextQuestion,
    CommentQuestion,
  },
})
export default class SurveyQuestion extends Vue {
  @Prop() value!: any;
  @Prop() schema!: any;

  get componentName() {
    return `${this.schema.type}-question`;
  }

  get model() {
    if (_.isString(this.value)) {
      return { text: this.value };
    }
    return this.value;
  }

  set model(newValue) {
    if (_.isString(this.value)) {
      const { text } = newValue;
      this.$emit('input', text);
    } else {
      const response = Object.assign({}, this.value, newValue);
      this.$emit('input', response);
    }
  }
}
