













import { Component, Vue, Watch, Prop } from 'vue-property-decorator';

@Component({
  name: 'DropdownQuestion',
})
export default class DropdownQuestion extends Vue {
  @Prop() value!: any;
  @Prop() schema!: any;

  model = (this.value || ({} as any)).value;

  @Watch('value')
  onValueChanged(newValue) {
    const response = newValue;
    this.model = response.value;
  }
  onInputChange(newValue) {
    const selected = (this.schema as any).choices.find(
      (choice) => choice.value === newValue
    );
    this.$emit('input', {
      text: selected.text,
      value: selected.value,
    });
  }
}
