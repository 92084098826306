












import { mapGetters } from 'vuex';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'SurveyProgress',
  computed: {
    ...mapGetters('participant', ['progressValue', 'surveyDesign']),
  },
})
export default class SurveyProgress extends Vue {
  readonly progressValue!: number;
  readonly surveyDesign!: any;

  get progressLabel() {
    return +this.progressValue.toFixed(0) + '&#37;';
  }

  get progressStyle() {
    const progressStyle: any = {};
    if (this.surveyDesign) {
      if (this.surveyDesign.buttonColor) {
        progressStyle.backgroundColor = this.surveyDesign.buttonColor;
      }
    }
    return progressStyle;
  }
}
