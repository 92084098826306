

















import { Component, Vue, Prop } from 'vue-property-decorator';
import { mapGetters } from 'vuex';

@Component({
  name: 'InfoPage',
  computed: {
    ...mapGetters('participant', ['survey', 'pages', 'template', 'clientLogo']),
  },
})
export default class InfoPage extends Vue {
  @Prop({
    type: String,
    default: '',
  })
  readonly heading!: string;

  readonly template!: any;

  @Prop({
    type: String,
    default: '',
  })
  readonly message!: string;

  get surveyLogo() {
    // TODO: refactor this??
    if (
      this.template &&
      this.template.name.indexOf('Culture Health Check') > -1
    ) {
      return require('@/assets/images/logo-chc-colour.png');
    }
    return require('@/assets/images/logo-culture-diagnostics-colour.png');
  }
}
