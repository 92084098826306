














import { mapGetters } from 'vuex';
import { PrimaryButton } from '../survey';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'SurveyNavigate',
  components: {
    PrimaryButton,
  },
  computed: {
    ...mapGetters('survey', ['hasNextPage', 'hasPrevPage', 'isLastPage']),
  },
})
export default class SurveyNavigate extends Vue {}
