































































































import _ from 'lodash';
import moment from 'moment';
import { mapGetters } from 'vuex';
import {
  InfoPage,
  SurveyWelcome,
  SurveyProgress,
  SurveyQuestion,
  PrimaryButton,
  SecondaryButton,
  SurveyPrint,
} from '@/components/survey';
import { Component, Mixins } from 'vue-property-decorator';
import { Dictionary } from 'vue-router/types/router';
import { BModal } from 'bootstrap-vue';
import NotificationMixin from '@/mixins/NotificationMixin';

moment.updateLocale('en', {
  relativeTime: {
    s: '%d seconds',
    ss: '%d seconds',
    m: '%d minute',
    mm: '%d minutes',
    h: '%d hour',
    hh: '%d hours',
    d: '%d day',
    dd: '%d days',
    M: '%d month',
    MM: '%d months',
    y: '%d year',
    yy: '%d years',
  },
});

@Component({
  name: 'Participant',
  components: {
    InfoPage,
    SurveyWelcome,
    PrimaryButton,
    SecondaryButton,
    SurveyProgress,
    SurveyQuestion,
    SurveyPrint,
  },
  computed: {
    ...mapGetters('participant', [
      'page',
      'participant',
      'responses',
      'hasNextPage',
      'hasPrevPage',
      'isLastPage',
      'validationObject',
      'status',
      'error',
      'pausedAt',
      'template',
    ]),
    ...mapGetters('auth', ['currentUser']),
  },
})
export default class Participant extends Mixins(NotificationMixin) {
  readonly $refs!: {
    idleModal: BModal;
  };

  readonly $v!: any;
  readonly page!: any;
  readonly isLastPage!: boolean;
  readonly validationObject!: any;
  readonly status!: string;
  readonly template!: any;

  // GETTERS
  get nextButtonText() {
    return this.isLastPage ? 'Submit' : 'Next';
  }

  get isPreview() {
    const previewQuery = this.$route.query.preview as string;
    return (previewQuery && parseInt(previewQuery) === 1) || false;
  }

  get participantSchema() {
    const totalQuestions = this.template.totalQuestions;
    if (this.template.enabledFeatures.completionTracking === true) {
      return {
        fullName: {
          questionIndex: totalQuestions,
          required: true,
          title: 'Please enter your full name.',
          description:
            'Please be assured this is only to let us know who has completed the survey and will not be linked to your survey answers which will remain completely anonymous.',
          placeholder: 'Type your full name here',
          type: 'text',
        },
      };
    }
    return {};
  }

  // METHODS
  getComponentName(type) {
    return `${type}-question`;
  }

  prevPage() {
    window.scrollTo(0, 0);
    this.$store.dispatch('participant/prevPage');
  }

  nextPage() {
    window.scrollTo(0, 0);
    this.$v.$touch();
    let validForm = true;
    _.forEach(this.page.elements, (element) => {
      if (this.$v.responses[element.questionIndex].$invalid) {
        validForm = false;
        return false;
      }
    });
    if (validForm && this.isLastPage) {
      _.forEach(this.participantSchema, (schema, key) => {
        if (this.$v.participant[key].$invalid) {
          validForm = false;
          return false;
        }
      });
    }
    if (!validForm) {
      this.notifyError('Please make sure all required fields are filled.');
      return;
    }

    this.$store.dispatch('participant/nextPage', { preview: this.isPreview });
    this.$v.$reset();
  }

  continueSurvey() {
    this.$store.dispatch('participant/startSurvey');
  }

  restartSurvey() {
    this.$store.dispatch('participant/restartSurvey');
  }

  // HOOKS
  created() {
    const client = this.$route.params.client;
    const surveySlug = this.$route.params.surveySlug;
    const params: Dictionary<string | boolean> = {
      client,
      surveySlug,
    };
    // TODO: enable page loading indicator
    params.preview = this.isPreview;
    this.$store.dispatch('participant/getClient', params);
    this.$store
      .dispatch('participant/getSurvey', params)
      .then(() => {
        if (this.isPreview) {
          this.notifyInfo('You are in preview mode.');
        }
      })
      .catch(() => {
        // do nothing for now
      });
  }

  validations() {
    if (this.isPreview) {
      const validations = {};
      for (const validation in this.validationObject.responses) {
        validations[validation] = {};
      }
      return {
        participant: {
          fullName: {},
        },
        responses: validations,
      };
    }
    return this.validationObject;
  }

  onIdle() {
    if (this.status === 'inprogress' && !this.isPreview) {
      this.$store.dispatch('participant/pauseSurvey');
      this.$refs.idleModal.show();
    }
  }
}
