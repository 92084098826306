













































import { mapGetters } from 'vuex';
import { SurveyQuestion, SurveyWelcome } from '../survey';

import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'SurveyPrint',
  components: {
    SurveyQuestion,
    SurveyWelcome,
  },
  computed: {
    ...mapGetters('participant', ['survey', 'pages', 'template', 'clientLogo']),
  },
})
export default class SurveyPrint extends Vue {
  responses: any = {};
  readonly template!: any;

  get surveyLogo() {
    // TODO: refactor this??
    if (
      this.template &&
      this.template.name.indexOf('Culture Health Check') > -1
    ) {
      return require('@/assets/images/logo-chc-colour.png');
    }
    return require('@/assets/images/logo-culture-diagnostics-colour.png');
  }
}
