










import { mapGetters } from 'vuex';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'PrimaryButton',
  inheritAttrs: false,
  computed: {
    ...mapGetters('participant', ['surveyDesign']),
  },
})
export default class PrimaryButton extends Vue {
  readonly surveyDesign!: any;

  get listeners() {
    return {
      ...this.$listeners,
    };
  }

  get buttonStyle() {
    const buttonStyle: any = {};
    if (this.surveyDesign) {
      if (this.surveyDesign.buttonColor) {
        buttonStyle.backgroundColor = this.surveyDesign.buttonColor;
        buttonStyle.borderColor = this.surveyDesign.buttonColor;
      }
    }
    return buttonStyle;
  }
}
