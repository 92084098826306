
































import _ from 'lodash';
import vueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';
import { SecondaryButton } from '../survey';

import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

@Component({
  name: 'RatingQuestion',
  components: {
    vueSlider,
    SecondaryButton,
  },
})
export default class RatingQuestion extends Vue {
  @Prop() value!: any;
  @Prop() schema!: any;

  dirty = false;
  options = {
    interval: 1,
    marks: true,
    hideLabel: true,
    eventType: 'auto',
    width: 'auto',
    height: 10,
    dotSize: 20,
    min: 1,
    max: 10,
    tooltip: 'always',
    tooltipPlacement: 'bottom',
    tooltipFormatter: this.formatTooltip.bind(this),
  };

  model = (this.value || {}).value || this.getMidPoint();

  @Watch('value')
  onValueUpdated(newValue) {
    const response = newValue || {};
    this.dirty = !!response.value;
    const midPoint = this.getMidPoint();
    this.model = response.value || midPoint;
    // this.midPoint = midPoint; TODO: Verify this
  }

  // METHODS
  reset() {
    const resetValue = this.schema.defaultValue || undefined;
    this.$emit('input', {
      text: resetValue,
      value: resetValue,
    });
  }

  onValueUpdate(ctx) {
    const midPoint = this.getMidPoint();
    // skip value update if clicking on midpoint and its not part of value
    const newValue =
      this.model === midPoint ? undefined : Math.ceil(this.model);
    this.$emit('input', {
      text: newValue,
      value: newValue,
    });
  }

  getMidPoint(includeMidpoint = false) {
    const rateMax = this.schema.rateMax || 10;
    const midPoint = (1 + rateMax) / 2;
    return midPoint;
  }

  formatTooltip(value) {
    return value === this.getMidPoint() ? '-' : value;
  }
}
