










































import _ from 'lodash';
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  name: 'CheckboxQuestion',
})
export default class CheckboxQuestion extends Vue {
  @Prop() value!: any;
  @Prop() schema!: any;

  get id() {
    return `Q${this.schema.questionIndex}`;
  }

  get model() {
    const response = this.value || {};
    return _.isArray(response.value) ? response.value.slice() : [];
  }

  set model(newValue) {
    const selectedValues = this.schema.choices.filter(
      (choice) => newValue.indexOf(choice.value) > -1
    );
    this.$emit('input', {
      text: selectedValues.map((selected) => selected.text).join(','),
      value: selectedValues.map((selected) => selected.value),
    });
  }
}
