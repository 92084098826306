










import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  name: 'TextQuestion',
})
export default class TextQuestion extends Vue {
  @Prop() value!: any;
  @Prop() schema!: any;

  get inputType() {
    return this.schema.inputType || 'text';
  }

  get placeholder() {
    return this.schema.placeholder || 'Type your answer here';
  }

  get model() {
    const { text } = this.value;
    return text;
  }

  set model(newValue) {
    this.$emit('input', {
      text: newValue,
    });
  }
}
