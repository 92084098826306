












import { mapGetters } from 'vuex';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'SecondaryButton',
  inheritAttrs: false,
  computed: {
    ...mapGetters('participant', ['surveyDesign']),
  },
})
export default class SecondaryButton extends Vue {
  isMouseOver = false;
  surveyDesign: any = null;

  get listeners() {
    return {
      ...this.$listeners,
    };
  }

  get buttonStyle() {
    const buttonStyle: any = {};
    if (this.surveyDesign && this.isMouseOver) {
      if (this.surveyDesign.buttonColor) {
        buttonStyle.backgroundColor = this.surveyDesign.buttonColor;
        buttonStyle.borderColor = this.surveyDesign.buttonColor;
      }
    }
    return buttonStyle;
  }
}
