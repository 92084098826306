











import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  name: 'CommentQuestion',
})
export default class CommentQuestion extends Vue {
  @Prop() value!: any;
  @Prop() schema!: any;

  get rows() {
    return this.schema.rows || 3;
  }

  get maxRows() {
    return this.schema.maxRows || 6;
  }

  get placeholder() {
    return this.schema.placeholder || 'Type your answer here';
  }

  get model() {
    const { text } = this.value || {};
    return text;
  }

  set model(newValue) {
    this.$emit('input', {
      text: newValue,
    });
  }
}
